<template >
    <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <span class="font-bold">{{ $t('message.additional') }}</span>

            <div class="mt-4">
                <el-table
                    :data="tableData"
                    border
                    style="width: 100%">
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                            {{ scope.row.name }}
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="scope">
                            <el-input
                                v-if="scope.row.key && !scope.row.calculate"
                                v-model="scope.row.value" 
                                :type="scope.row.type ? scope.row.type : 'text'"
                                @input="updateInput($event, scope.row)"
                                size="mini">
                            </el-input>
                            <span v-if="scope.row.key && scope.row.calculate" class="font-bold">
                                {{ scope.row.value | formatNumber(1) }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
  
<script>
  import { mapGetters, mapActions } from "vuex";
  import { evaluate } from 'mathjs';
  export default {
    props: {
        form: {
            type: Object,
            default: Object
        },
        old_data: {
            type: Array,
            default: Array
        }
    },
    data() {
      return {
        tableData: [],
        formulaRows: [],
      };
    },
    watch: {
        old_data: {
            handler: async function(newVal, oldVal) {
                if(newVal && Array.isArray(newVal) && newVal.length > 0){
                    this.tableData = JSON.parse(JSON.stringify(newVal));
                    this.setDataToTable();
                } else {
                    this.tableData = JSON.parse(JSON.stringify(this.addParams));
                    this.setDataToTable();
                }
            },
            deep: true,
            immediate: true
        },

        tableData: {
            handler: async function(newVal, oldVal) {
              this.$emit('input', newVal);
            },
            deep: true,
            immediate: true
        },
    },
    created() {},
    computed: {
        ...mapGetters({
            addParams: "deals/firstStepLandAddParams",
        }),
    },
    methods: {
        setDataToTable() {
            this.tableData.forEach((element, index) => {
                element.value = this.calculateRowAndSet(element);
            });
        },
        calculateRowAndSet(updated_row) {
            let val = JSON.parse(JSON.stringify(updated_row.value));
            if(updated_row && updated_row.calculate && updated_row.formula){
                val = this.evaluateFormula(updated_row.formula);
            }
            return val;
        },
        updateInput(val, row) {
            this.setDataToTable();
        },
        evaluateFormula(formula) {
            let scope = {};
            this.tableData.forEach(item => {
                scope[item.key] = item.value;
            });
            return _.round(evaluate(formula, scope), 3);
        }
    },
  };
</script>
  