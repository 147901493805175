<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.category')" filterable clearable
      :size="size" class="w-100">
      <el-option v-for="(category, index) in categories" :key="'categories-' + index" :label="category.name" :value="category.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      no_children: {
        default: false
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      this.updateInventory({no_children: this.no_children})
    },
    computed: {
      ...mapGetters({
        categories: 'categories/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'categories/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
