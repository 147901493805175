<template>
    <div>
        <div class="app-modal__body p-5 pb-0">
            <div class="timeline-items__right rounded-sm w-100 p-4">
                <template>
                    <div class="d-flex justify-between">
                        <h4>{{ table.table_name }}</h4>
                        <el-checkbox v-model="completed" @change="handleCheckChange" :label="$t('message.completed_z')" border></el-checkbox>
                    </div>
                    <el-table
                        :data="table.table_values"
                        show-summary
                        :summary-method="getMainTableSummaries"
                        border
                        style="width: 100%"
                    >

                        <el-table-column :label="$t('message.pricing_object_name')">
                            <template slot-scope="scope">
                                <span class="font-bold"> {{ object_name }} </span>
                            </template>
                        </el-table-column>

                        <!-- TABLE COLUMNS CREATION -->
                        <el-table-column v-for="(col, col_index) in table.column_names" :key="'name_'+col_index" :label="col.name">
                            <!-- HAVE CHILDREN -->
                            <el-table-column 
                                v-if="col.children && col.children.length > 0" 
                                v-for="(child, child_index) in col.children" :key="'name_'+child_index" :label="child.name"
                            >
                                <template slot-scope="scope">
                                    <div :class="child.key == scope.row.key ? 'mm_constant_values' : ''">
                                        <el-input disabled v-model="scope.row.values[(child_index+(col.children.length * col_index))]" type="number"></el-input>
                                    </div>
                                </template>
                            </el-table-column>
                            
                            <!-- HAVE NO CHILDREN -->
                            <template v-else slot-scope="scope">
                                <div :class="col.key == scope.row.key ? 'mm_constant_values' : ''">
                                    <el-input v-model="scope.row.values[col_index]" type="number" size="mini"></el-input>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Рыночная  стоимость" width="200">
                            <template slot-scope="scope">
                                {{ table.total_last | formatNumber(1) }} {{ $currency }}
                            </template>
                        </el-table-column>

                    </el-table>
                </template>
            </div>
        </div>

        <div v-if="mainFormData && mainFormData.id && formCompleted" class="app-modal__body p-5 pb-0">
            <div class="timeline-items__right rounded-sm w-100 p-4">
                <div class="d-flex">
                    <el-button @click="downloadExcelFiles()" type="primary" icon="fa-solid fa-x el-icon--left">{{ $t('message.downloadExcel') }}</el-button>
                    <div 
                        v-if="mainFormData.documentTemplates"
                        v-for="templ in mainFormData.documentTemplates" :key="'template_'+templ.id"
                    >
                        <el-button  @click="showFileOpen(templ)" class="ml-5" type="primary" icon="fa-solid fa-w el-icon--left">{{ templ.name }}</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import { formatNumber } from "@/filters/index";

export default {
    props:{
        object_name: {
            type: String,
            default: String,
        },
        fifthStepTable:{
            type: Array,
            default: Array
        },
        drawerOpened:{
            type: Boolean
        },
        mainFormData:{
            type: Object,
            default: Object
        },
        formCompleted:{
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            table: {},
            mainFormDataCopy: {},
            completed: false,
        };
    },
    
    watch: {
        table: {
            handler: async function(newVal, oldVal) {
                this.emitData(this);
            },
            deep: true,
            immediate: true
        },
        fifthStepTable: {
            handler: async function(newVal, oldVal) {
                this.setFifthStepTable(this, newVal);
            },
            deep: true,
            immediate: true
        },
        mainFormData: {
            handler: function(newVal, oldVal) {
                if(newVal){
                    this.setFormData(this);
                }
            },
            deep: true,
            immediate: true
        },
        formCompleted: {
            handler: function(newVal, oldVal) {
                this.completed = JSON.parse(JSON.stringify(newVal));
            },
            deep: true,
            immediate: true
        },
    },
    created() {
        
    },
    computed: {
        ...mapGetters({
          fifthStepTotal: "deals/fifthStepTotal",
        }),
    },
    methods: {
        ...mapActions({
            downloadExcel: "deals/downloadExcel",
        }),
        handleCheckChange(completed) {
            this.$emit('setFormCompleted', completed);
        },
        setValues() {
            // EXPENSE
            let physical_deterioration_total = this.totalAfterDeterioration();
            this.table.table_values[0].values[0] = parseFloat(physical_deterioration_total || 0);
            this.table.table_values[0].values[1] = this.calcPercent("expensive");
            this.table.table_values[0].values[2] = _.round(parseFloat(physical_deterioration_total || 0) * parseFloat(this.table.table_values[0].values[1] || 0), 2);

            // COMPARE
            let avarage_compare_total = this.avarageCompareTotal();
            this.table.table_values[0].values[3] = parseFloat(avarage_compare_total || 0);
            this.table.table_values[0].values[4] = this.calcPercent("comparative");
            this.table.table_values[0].values[5] = _.round(parseFloat(avarage_compare_total || 0) * parseFloat(this.table.table_values[0].values[4] || 0), 2);
            this.table.total_last = this.table.table_values[0].values[2] + this.table.table_values[0].values[5];
        },
        setFifthStepTable: _.debounce(function(self, newVal) {
            if(newVal && Array.isArray(newVal) && newVal.length > 0){
                self.table = JSON.parse(JSON.stringify(newVal));
                self.setValues();
            }else {
                self.table = JSON.parse(JSON.stringify(self.fifthStepTotal));
                self.setValues();
            }
        }, 500),

        setFormData: _.debounce(function(self) {
            self.mainFormDataCopy = JSON.parse(JSON.stringify(self.mainFormData));
            self.setValues();
        }, 500),

        emitData:  _.debounce(function(self) {
            self.$emit('input', self.table);
        }, 500),
        // EXPENSE
        totalAfterDeterioration() {
            // (* parseFloat(this.mainFormData.currency_rate || 0)) 
            let calc = parseFloat(this.mainFormData.price || 0) * ((100 - parseFloat(this.mainFormData.physical_deterioration || 0)) / 100);
            return _.round(parseFloat(calc || 0), 2);
        },

        // COMPARE
        avarageCompareTotal() {
            let total_comp = 0;
            if(this.checkCompTables()){
                let attr_count = this.mainFormDataCopy.attributeComporisonTable.length;
                let comp_count = this.mainFormDataCopy.comporisonTable.length;
                if(attr_count > 0 && comp_count > 0){
                    let analogs = this.mainFormDataCopy.attributeComporisonTable[attr_count-1].analogs;
                    let sum = analogs.reduce((accumulator, row) => {
                        return accumulator + (this.stringToFloat(row.total) );
                    }, 0);
                    total_comp = sum / comp_count;
                }
                if(total_comp == 0 && attr_count > 0 && comp_count > 0){
                    this.updateData();
                    total_comp = this.avarageCompareTotal();
                }
            }

            let total_land_comp = 0;
            if(this.checkCompLandTables()){
                let attr_count = this.mainFormDataCopy.attributeComporisonLandTable.length;
                let comp_count = this.mainFormDataCopy.comporisonLandTable.length;
                if(attr_count > 0 && comp_count > 0){
                    let analogs = this.mainFormDataCopy.attributeComporisonLandTable[attr_count-1].analogs;
                    let sum = analogs.reduce((accumulator, row) => {
                        return accumulator + (this.stringToFloat(row.total) );
                    }, 0);
                    total_land_comp = sum / comp_count;
                }
                if(total_land_comp == 0 && attr_count > 0 && comp_count > 0){
                    this.updateData();
                    total_land_comp = this.avarageCompareTotal();
                }
            }
            let total = parseFloat(total_comp) + parseFloat(total_land_comp);
            return _.round(parseFloat(total), 2);
        },

        updateData(){
            if(this.checkCompTables()){
                this.mainFormDataCopy.attributeComporisonTable.forEach((element, el_index) => {
                    if(el_index == 0 && element.analogs){
                        element.analogs.forEach((col, col_index) => {
                            let row = this.mainFormDataCopy.comporisonTable[col_index];
                            let total = row.price * row.currency_rate;
                            total = total * (100 + parseFloat(col.value_2 || 0))/100;
                            this.$set(col, 'total', total);
                        });
                    } else if (el_index > 0 && element.analogs) {
                        element.analogs.forEach((col, col_index) => {
                            let total = this.mainFormDataCopy.attributeComporisonTable[el_index-1].analogs[col_index].total;
                            total = total * (100 + parseFloat(col.value_2 || 0))/100;
                            this.$set(col, 'total', total);
                        });
                    }
                });
            }
        },

        checkCompTables() {
            return (this.mainFormDataCopy && 
            this.mainFormDataCopy.hasOwnProperty('attributeComporisonTable') &&
            this.mainFormDataCopy.hasOwnProperty('comporisonTable') && 
            this.mainFormDataCopy.attributeComporisonTable && 
            this.mainFormDataCopy.comporisonTable && 
            this.mainFormDataCopy.attributeComporisonTable.length > 0 && 
            this.mainFormDataCopy.comporisonTable.length > 0) ? true : false
        },

        checkCompLandTables() {
            return (this.mainFormDataCopy && 
            this.mainFormDataCopy.hasOwnProperty('attributeComporisonLandTable') &&
            this.mainFormDataCopy.hasOwnProperty('comporisonLandTable') && 
            this.mainFormDataCopy.attributeComporisonLandTable && 
            this.mainFormDataCopy.comporisonLandTable && 
            this.mainFormDataCopy.attributeComporisonLandTable.length > 0 && 
            this.mainFormDataCopy.comporisonLandTable.length > 0) ? true : false
        },

        calcPercent(calc_key = "comparative") {
            // "expensive" , "comparative"
            let totals_table = this.mainFormData.fourthStepTables.find(el => el && (el.name == "total" || el.name == "criteria_11"));
            if(totals_table){
                let row = totals_table.table_values.find(val => val.hasOwnProperty('key') && val.key && val.key == calc_key);
                return _.round((row ? row.total_last : 0), 2);
            } else {
                return 0;
            }
        },

        stringToFloat(str) {
            const num = parseFloat(str || 0);
            return isNaN(num) ? 0 : num;
        },

        getMainTableSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                
                if (index === 7) {
                    sums[index] = formatNumber(this.table.total_last, 1) + ' ' + this.$currency;
                    return;
                }
            });

            return sums;
        },

        showFileOpen(template) {
            let file_name = template.name + '.docx';
            this.$openOffice(file_name, "create", JSON.stringify(this.mainFormData));
        },

        downloadExcelFiles() {
            this.downloadExcel({id: this.mainFormData.id})
                .then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel"
                    })
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob)
                    link.setAttribute("download", (this.mainFormData.id+'-deal.xlsx'));
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    console.log(err, 'errrr');
                });
        },

    },
};
</script>
<style>
  .mm_constant_values input {
    background-color: rgb(255, 186, 186);
    width: 100%;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>