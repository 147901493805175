<template >
    <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <template>
                <h4>{{ table.table_name }}</h4>
                <el-table
                    :data="table.table_values"
                    show-summary
                    :summary-method="getMainTableSummaries"
                    border
                    style="width: 100%"
                >

                    <el-table-column :label="$t('message.criteria')">
                        <template slot-scope="scope">
                            {{ scope.row.name }}
                        </template>
                    </el-table-column>

                    <!-- TABLE COLUMNS CREATION -->
                    <el-table-column v-for="(col, col_index) in table.column_names" :key="'name_'+col_index" :label="col.name">
                        <!-- HAVE CHILDREN -->
                        <el-table-column 
                            v-if="col.children && col.children.length > 0" 
                            v-for="(child, child_index) in col.children" :key="'name_'+child_index" :label="child.name"
                        >
                            <!-- CHILD HAS VALUE -->
                            <el-table-column v-if="child.value" :label="child.value.toString()">
                                <template slot-scope="scope">
                                    <div :class="child.key == scope.row.key ? 'mm_constant_values' : ''">
                                        <el-input v-model="scope.row.values[child_index]" type="number" size="mini"></el-input>
                                    </div>
                                </template>
                            </el-table-column>

                            <!-- CHILD HAS NO VALUE -->
                            <template v-else slot-scope="scope">
                                <div :class="child.key == scope.row.key ? 'mm_constant_values' : ''">
                                    <el-input v-model="scope.row.values[child_index]" type="number" size="mini"></el-input>
                                </div>
                            </template>
                        </el-table-column>
                        
                        <!-- HAVE NO CHILDREN -->
                        <template v-else slot-scope="scope">
                            <div :class="col.key == scope.row.key ? 'mm_constant_values' : ''">
                                <el-input v-model="scope.row.values[col_index]" type="number" size="mini"></el-input>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Итог.знач. веса" width="140">
                        <template slot-scope="scope">
                            {{ scope.row.total_last }}
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props:{
        selectedTable:{
            type: Object,
            default: Object
        },
        drawerOpened:{
            type: Boolean
        }
    },
    data() {
        return {
            table: {},
        };
    },
    watch: {
        selectedTable: {
            handler: async function(newVal, oldVal) {
                this.table = JSON.parse(JSON.stringify(newVal));
                setTimeout(() => {
                    this.updateTableValues();
                }, 500);
            },
            deep: true,
            immediate: true
        },
        "table.table_values": {
            handler: async function(newVal, oldVal) {
                this.updateTableValues();
            },
            deep: true,
            immediate: true
        },
    },
    created() {
        
    },
    computed: {
        ...mapGetters({
        }),
        getMainTable() {
            return this.tables.find(el => el.name == 'main');
        },
    },
    methods: {
        ...mapActions({
        }),

        updateTableValues() {
            let total_first = 0;
            let total_last = 0;
            this.table.table_values.forEach(element => {
                element.total_first = this.rowCalculation(element)
                total_first += element.total_first;
            });
            this.$set(this.table, 'total_first', parseFloat(total_first || 0));

            this.table.table_values.forEach(element => {
                element.total_last = this.rowCalculationSec(element)
                total_last += element.total_last;
            });
            this.$set(this.table, 'total_last', parseFloat(total_last || 0));
            this.$emit('input', this.table);
        },

        rowCalculation(row) {
            let result = 0;
            if(row.values && row.values.length > 0){
                let has_value = row.values.filter(el => parseFloat(el || 0) > 0);
                if(has_value && has_value.length > 0){
                    result = has_value.reduce((accumulator, currentValue) => accumulator * parseFloat(currentValue || 0), 1);
                    result = result ** (1/has_value.length);
                }
            }
            return _.round(result, 2);
        },

        rowCalculationSec(row) {
            let total = this.table.total_first && this.table.total_first > 0 ? _.round(row.total_first/this.table.total_first, 2) : 0;
            return _.round(total, 2);
        },

        calcRowFormation(row, row_index, column_index) {
            if(row_index > column_index){
                let val = parseFloat(this.table.table_values[column_index].values[row_index] || 0);
                if(val == 0){
                    return 0;
                }else {
                    return _.round(1/val, 2);
                }
            }
        },

        getMainTableSummaries(param) {
            const { columns } = param;
            const sums = [];
            let col_count = 0;
            if(this.table.column_names){
                this.table.column_names.forEach(element => {
                    if(element.children){
                        col_count += element.children.length
                    }else {
                        col_count += 1;
                    }
                });
            }
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if(index === 1 + col_count){
                    sums[index] = this.table.total_last;
                    return;
                }
            });

            return sums;
        },

    },
};
</script>
<style>
  .mm_constant_values input {
    background-color: rgb(255, 186, 186);
    width: 100%;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>