<template>
  <div>
        <div class="app-modal__body p-5 pb-0">
          <div class="timeline-items__right rounded-sm w-100 p-4">
            <template>
              <el-table
                :data="secondStepTableData"
                :summary-method="getTableSummaries"
                show-summary
                border
                style="width: 100%">
                <el-table-column
                  type="index"
                  width="50">
                </el-table-column>
                <el-table-column
                  prop="name"
                  :label="$t('message.name')">
                  <template slot-scope="scope">
                    {{ scope.row.attribute ? scope.row.attribute.name : '' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="value_1"
                  label="Удельный весь КЭ, %">
                  <template slot-scope="scope">
                    <el-input
                        placeholder="Удельный весь КЭ, %"
                        type="number"
                        v-model="scope.row.value_1"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="value_2"
                  label="Физический износ, %">
                  <template slot-scope="scope">
                    <el-input
                        placeholder="Физический износ, %"
                        type="number"
                        v-model="scope.row.value_2"
                    ></el-input>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Взвешенное значение, %">
                  <template slot-scope="scope">
                    {{ secondTableRowTotal(scope.row)}}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        tableData: {
            type: Array,
            default: Array,
        },
        category_id: {
            type: Number,
        },
    },
    data() {
        return {
            secondStepTableData: [],
        };
    },
    watch: {
        tableData: {
            handler: async function(newVal, oldVal) {
                if(newVal && Array.isArray(newVal) && newVal.length == 0 && this.category_id) {
                  this.updateAttributeList({category_id: this.category_id})
                    .then(res => {
                        this.secondStepTableData = (this.attributes && this.attributes[2] ? JSON.parse(JSON.stringify(this.attributes[2])) : []);
                    })
                    .catch(err => {
                        console.log(err, 'errerr');
                    });
                } else {
                  this.secondStepTableData = JSON.parse(JSON.stringify(newVal));
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() {},
    computed: {
        ...mapGetters({
          attributes: "categories/attributes",  
        }),
        secondTableTotal() {
            let sum = this.secondStepTableData.reduce((accumulator, row) => {
                return accumulator + (this.stringToFloat(row.value_1) * this.stringToFloat(row.value_2) / 100);
            }, 0);

            this.$emit('getTableData', this.secondStepTableData);

            sum = _.round(sum, 2);
            if(this.secondStepTableData && this.secondStepTableData.length > 0){
              this.$emit('input', sum);
            }
            return sum;
        },
        secondTableFirstColumnTotal() {
            let sum = this.secondStepTableData.reduce((accumulator, row) => {
                return accumulator + this.stringToFloat(row.value_1);
            }, 0);
            return sum;
        },
        secondTableSecondColumnTotal() {
            let sum = this.secondStepTableData.reduce((accumulator, row) => {
                return accumulator + this.stringToFloat(row.value_2);
            }, 0);
            return sum;
        },
    },
    methods: {
        ...mapActions({
          updateAttributeList: "categories/getAttributes",
        }),
        secondTableRowTotal(row) {
            return this.stringToFloat(row.value_1) * this.stringToFloat(row.value_2) / 100;
        },
        stringToFloat(str) {
            const num = parseFloat(str || 0);
            return isNaN(num) ? 0 : num;
        },

        getTableSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (index === 2) {
                    sums[index] = this.secondTableFirstColumnTotal + ' %';
                    return;
                }
                
                if (index === 3) {
                    sums[index] = this.secondTableSecondColumnTotal + ' %';
                    return;
                }
                if (index === 4) {
                    sums[index] = this.secondTableTotal + ' %';
                    return;
                }
            });

            return sums;
        },
    },
}
</script>
