<template >
    <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <template>
                <el-table
                    :data="tableData"
                    border
                    style="width: 100%">
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                            {{ form.object_name ? form.object_name : $t('message.object_name') }}
                        </template>
                    </el-table-column>
                    <el-table-column label="С восст">
                        <template slot-scope="scope">
                            {{ priceStringFormatter(form.price) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Физический износ %">
                        <template slot-scope="scope">
                            {{ priceStringFormatter(form.physical_deterioration) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Стоимость с учетом износа">
                        <template slot-scope="scope">
                            {{ totalAfterDeterioration}}
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
    </div>
</template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    props: {
        form: {
            type: Object,
            default: Object
        }
    },
    data() {
      return {
        tableData: [
            {
            date: '2016-05-02',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
            }
        ]
      };
    },
    created() {},
    computed: {
        totalAfterDeterioration() {
            let calc = parseFloat(this.form.price || 0) * ((100 - parseFloat(this.form.physical_deterioration || 0)) / 100);
            return this.priceStringFormatter(calc);
        }
    },
    methods: {
        priceStringFormatter(price) {
            return price ? price.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00';
        },
    },
  };
  </script>
  