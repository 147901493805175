<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> 
            {{ $t("message.new_m", { m: $t("message.deal") }) }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="d-flex p-5 pb-0">
        <div class="w-100 mr-5">
          <el-steps :space="200" :active="activeStep" simple>
            <el-step title="Step 1" icon="el-icon-edit"></el-step>
            <el-step title="Step 2" icon="el-icon-upload"></el-step>
            <el-step title="Step 3" icon="el-icon-picture"></el-step>
            <el-step title="Step 4" icon="el-icon-picture"></el-step>
            <el-step title="Step 5" icon="el-icon-picture"></el-step>
          </el-steps>
        </div>
        
        <el-button :disabled="activeStep == 1" @click="prev" icon="el-icon-back"></el-button>
        <el-button :disabled="activeStep == 5" @click="next" icon="el-icon-right"></el-button>
      </div>

        <div v-show="activeStep == 1" class="mb-5">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">

                    <el-row :gutter="20">
                        <el-col :span="24 ">
                          <el-form-item prop="client_id"
                            :label="$t('message.client')"
                            >
                              <select-client
                                @getClient="getClient"
                                :size="'large'"
                                :placeholder="$t('message.client')"
                                :id="form.client_id"
                                v-model="form.client_id"
                              >
                              </select-client>
                          </el-form-item>     
                        </el-col>
                        <el-col :span="6">
                          <span class="font-bold">{{ $t('message.full_name') }}: </span> {{ client.name ? client.name : '' }}
                        </el-col>
                        <el-col :span="6">
                          <span class="font-bold">{{ $t('message.date_of_birth') }}: </span> {{ client.date_of_birth ? client.date_of_birth : '' }}
                        </el-col>
                        <el-col :span="6">
                          <span class="font-bold">{{ $t('message.phone') }}: </span> {{ client.phone ? client.phone : '' }}
                        </el-col>
                        <el-col :span="6">
                          <span class="font-bold">{{ $t('message.email') }}: </span> {{ client.email ? client.email : '' }}
                        </el-col>
                    </el-row>
                </div>
              </div>
              <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4 tab-btunn">
                  <p class="font-bold">Оплата: {{ form.paymentTotal | formatNumber(1) }} {{ $currency }}</p>
                  <el-button @click="drawerPayment = true" type="primary">Оплата сделки</el-button>     
                </div>
              </div>      
              
              <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                  <el-row :gutter="20">
                      <el-col :span="16">
                        <el-form-item prop="object_name" :label="$t('message.object_name')" >
                            <el-input
                                :placeholder="$t('message.object_name')"
                                v-model="form.object_name"
                            ></el-input>
                          </el-form-item>  
                      </el-col>
                      <el-col :span="8">
                        <el-form-item prop="category_id"
                          :label="$t('message.category')"
                          >
                            <select-category
                              :size="'large'"
                              :placeholder="$t('message.category')"
                              :no_children="true"
                              :id="form.category_id"
                              v-model="form.category_id"
                              @input="updateCategory"
                            >
                            </select-category>
                        </el-form-item>     
                      </el-col>
                  </el-row>
                  
                  <el-row :gutter="20">
                      <el-col :span="8">
                        <el-form-item prop="city" :label="$t('message.city')" >
                            <select-city
                              :size="'large'"
                              :placeholder="$t('message.city')"
                              :id="form.city_id"
                              v-model="form.city_id"
                            >
                            </select-city>
                          </el-form-item>  
                      </el-col>

                      <el-col :span="8">
                        <el-form-item prop="region" :label="$t('message.region')">
                            <select-region
                              :size="'large'"
                              :placeholder="$t('message.region')"
                              :id="form.region_id"
                              v-model="form.region_id"
                            >
                            </select-region>
                          </el-form-item>  
                      </el-col>
                      
                      <el-col :span="8">
                        <el-form-item prop="full_address" :label="$t('message.full_address')">
                          <el-input :placeholder="$t('message.full_address')" v-model="form.full_address"></el-input>
                        </el-form-item>  
                      </el-col>
                  </el-row>
                  <el-row :gutter="20">
                      <el-col :span="6">
                        <el-form-item prop="client_price" :label="$t('message.price')" >
                            <crm-money-input
                                v-model="form.client_price"
                                :old="form.client_price"
                              >
                            </crm-money-input>
                          </el-form-item>  
                      </el-col>

                      <el-col :span="6">
                        <el-form-item prop="currency_rate" :label="$t('message.rate')" >
                            <el-input
                                :disabled="selectedCurrency && selectedCurrency.active"
                                v-model="form.currency_rate"
                                :old="form.currency_rate"
                                type="number"
                              >
                              <template slot="append">{{ calculateInActiveCurrency | formatNumber(1) }}  {{ $currency }}</template>
                            </el-input>
                          </el-form-item>  
                      </el-col>

                      <el-col :span="6">
                        <el-form-item prop="currency_id" :label="$t('message.currency')" >
                            <select-currency
                              :size="'large'"
                              :placeholder="$t('message.currency')"
                              @setCurrencyRate="setCurrencyRate"
                              :id="form.currency_id"
                              v-model="form.currency_id"
                            >
                            </select-currency>
                          </el-form-item>  
                      </el-col>

                      <el-col :span="6">
                        <el-form-item prop="physical_deterioration" label="Физический износ %">
                            <el-input
                                placeholder="Физический износ %"
                                v-model="form.physical_deterioration"
                            ></el-input>
                          </el-form-item>  
                      </el-col>
                  </el-row>
                  <el-row v-show="selectedCategory.calculation_type && selectedCategory.calculation_type != 1" :gutter="20">
                      <el-col :span="3">
                        <el-form-item prop="quantity" :label="$t('message.quantity')" >
                            <el-input
                                type="number"
                                :placeholder="$t('message.quantity')"
                                v-model="form.quantity"
                            ></el-input>
                          </el-form-item>  
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="cadastre" :label="$t('message.cadastre')" >
                            <el-input
                                :placeholder="$t('message.cadastre')"
                                v-model="form.cadastre"
                            ></el-input>
                          </el-form-item>  
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="state_warrant" :label="$t('message.state_warrant')" >
                            <el-input
                                :placeholder="$t('message.state_warrant')"
                                v-model="form.state_warrant"
                            ></el-input>
                          </el-form-item>  
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="total_usable_area" :label="$t('message.total_usable_area')" >
                            <el-input
                                :placeholder="$t('message.total_usable_area')"
                                v-model="form.total_usable_area"
                            ></el-input>
                          </el-form-item>  
                      </el-col>
                      <el-col :span="3">
                        <el-form-item prop="number_of_floors" :label="$t('message.number_of_floors')" >
                            <el-input
                                :placeholder="$t('message.number_of_floors')"
                                v-model="form.number_of_floors"
                            ></el-input>
                          </el-form-item>  
                      </el-col>
                  </el-row>
                </div>
              </div>

              <PhysicalDeteriorationCalc 
                :form="{object_name: form.object_name, price: form.price, physical_deterioration: form.physical_deterioration}">
              </PhysicalDeteriorationCalc>

              <LandAdditionalParams
                v-if="selectedCategory && selectedCategory.calculation_type && [2, 3].includes(selectedCategory.calculation_type)"
                v-model="form.other_inputs"
              >
              </LandAdditionalParams>

              <AvtoAdditionalParams
                v-if="selectedCategory && selectedCategory.calculation_type && selectedCategory.calculation_type == 1"
                v-model="form.other_inputs"
              >
              </AvtoAdditionalParams>
          </el-form>
        </div>

      <div v-show="activeStep == 2" class="mb-5">
        <SecondTable
          v-model="form.physical_deterioration"
          @getTableData="setToForm($event, 'physicalDeteriorationParamsTable')"
          :tableData="secondStepTableData"
        >
        </SecondTable>
        <PhysicalDeteriorationCalc
          :form="{object_name: form.object_name, price: form.price, physical_deterioration: form.physical_deterioration}">
        </PhysicalDeteriorationCalc>
      </div>

      <div v-show="activeStep == 3" class="mb-5 deals-third-step">
        <el-tabs v-if="selectedCategory && selectedCategory.calculation_type && selectedCategory.calculation_type == 3" type="card" class="mt-1">
          <el-tab-pane label="Сравнит дома">
            <ThirdTable
              :additional_title="'дома'"
              :selectedCategory="selectedCategory"
              :attributeComporison="thirdStepAttributeComporisonTable"
              :thirdStepComporisonTable="thirdStepComporisonTable"
              @attributeComporisonTable="setToForm($event, 'attributeComporisonTable')"
              @comporisonTable="setToForm($event, 'comporisonTable')"
            >
            </ThirdTable>
          </el-tab-pane>
          <el-tab-pane label="Сравнит земли">
            <ThirdTable
              :additional_title="'земли'"
              :selectedCategory="selectedCategory"
              :attributeComporison="thirdStepAttributeComporisonLandTable"
              :thirdStepComporisonTable="thirdStepComporisonLandTable"
              @attributeComporisonTable="setToForm($event, 'attributeComporisonLandTable')"
              @comporisonTable="setToForm($event, 'comporisonLandTable')"
            >
            </ThirdTable>
          </el-tab-pane>
        </el-tabs>

        <ThirdTable
          v-else
          :selectedCategory="selectedCategory"
          :attributeComporison="thirdStepAttributeComporisonTable"
          :thirdStepComporisonTable="thirdStepComporisonTable"
          @attributeComporisonTable="setToForm($event, 'attributeComporisonTable')"
          @comporisonTable="setToForm($event, 'comporisonTable')"
        >
        </ThirdTable>
        
      </div>
      
      <div v-show="activeStep == 4" class="mb-5">
        <FourthStepAvtoTables
          v-if="selectedCategory && selectedCategory.calculation_type && selectedCategory.calculation_type == 1"
          v-model="form.fourthStepTables"
          :fourthStepTables="fourthStepTables"
          :drawerOpened="selfDrawerOpened">
        </FourthStepAvtoTables>
        <FourthStepLandTables 
          v-else-if="selectedCategory && selectedCategory.calculation_type && [2, 3].includes(selectedCategory.calculation_type)"
          v-model="form.fourthStepTables"
          :fourthStepTables="fourthStepTables"
          :drawerOpened="selfDrawerOpened">
        </FourthStepLandTables>
      </div>

      <div v-show="activeStep == 5" class="mb-5">
        <FifthTable
          :object_name="form.object_name"
          v-model="form.fifthStepTable"
          :fifthStepTable="fifthStepTable"
          :drawerOpened="selfDrawerOpened"
          :mainFormData="form"
        >
        </FifthTable>
      </div>

      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
            :with-header="false"  
            :visible.sync="drawerPayment" 
            ref="drawerPayment"
            size="70%"
            :append-to-body="true"
            @opened="drawerOpened('drawerPaymentChild')"
            @closed="drawerClosed('drawerPaymentChild')"
            >
            <div>
              <Payments
                v-model="form.paymentTotal"
                :old_payments="form.payments"
                @setPayments="setPayments"
                ref="drawerPaymentChild" 
                drawer="drawerPayment"> 
              </Payments>
            </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import child_list from "@/utils/mixins/child_list";
import SelectClient from "@/components/inventory/select-client.vue";
import SelectCategory from "@/components/inventory/select-category.vue";
import FourthStepAvtoTables from "./fourth-step/avto-tables.vue";
import FourthStepLandTables from "./fourth-step/land-tables.vue";
import ThirdTable from "./third-step/third-table.vue";
import SecondTable from "./second-step/second-table.vue";
import PhysicalDeteriorationCalc from "./first-step/physical-deterioration-calc.vue";
import LandAdditionalParams from "./first-step/land-additional-params.vue";
import AvtoAdditionalParams from "./first-step/avto-additional-params.vue";
import SelectCity from "@/components/inventory/select-city.vue";
import SelectRegion from "@/components/inventory/select-region.vue";
import SelectCurrency from "@/components/inventory/select-currency.vue";
import FifthTable from "./fifth-step/total-table.vue";
import Payments from "./payments.vue";

export default {
  
  mixins: [form, drawer, child_list],
  components: {
    SelectClient,
    SelectCategory,
    SelectCity,
    SelectRegion,
    SecondTable,
    ThirdTable,
    FourthStepAvtoTables,
    FourthStepLandTables,
    PhysicalDeteriorationCalc,
    LandAdditionalParams,
    AvtoAdditionalParams,
    SelectCurrency,
    FifthTable,
    Payments
  },
  data() {
    return {
      activeStep: 1,
      selfDrawerOpened: false,
      client: {},
      secondStepTableData: [],
      thirdStepAttributeComporisonTable: [],
      thirdStepComporisonTable: [],
      thirdStepAttributeComporisonLandTable: [],
      thirdStepComporisonLandTable: [],
      fourthStepTables: [],
      fifthStepTable: [],
      drawerPayment: false,
      selectedCategory: {},
      selectedCurrency: null,
    };
  },
  created() {
  },
  watch: {
    activeStep: {
        handler: async function(newVal, oldVal) {
          this.setSecondTableValues();
        },
        deep: true,
        immediate: true
    },
    "form.other_inputs": {
        handler: async function(newVal, oldVal) {
          this.updatePrice();
        },
        deep: true,
        immediate: true
    },
  },
  computed: {
      ...mapGetters({
          rules: "deals/rules",
          model: "deals/model",
          columns: "deals/columns",
          categories: 'categories/inventory',
          attributes: "categories/attributes",
      }),
      calculateInActiveCurrency() {
        let total = parseFloat(this.form.currency_rate || 0) * parseFloat(this.form.client_price || 0);
        return _.round(total, 2);
      }
  },
  methods: {
      ...mapActions({
          save: "deals/store",
          updateList: "categories/getAttributes",
      }),
      setPayments(payments) {
        this.form.payments = JSON.parse(JSON.stringify(payments));
      },
      setCurrencyRate(currency) {
        if(currency){
          this.selectedCurrency = currency;
          this.$set(this.form, 'currency_rate', currency.rate);
        }
      },
      setToForm(data, key) {
        this.form[key] = JSON.parse(JSON.stringify(data));
      },
      afterOpen() {
        this.selfDrawerOpened = true;
      },
      updatePrice() {
          let price_obj = this.form.other_inputs && this.form.other_inputs.find(el => el.key == 'valuation_date_replacement_cost');
          if(this.selectedCategory && this.selectedCategory.calculation_type && [2, 3].includes(this.selectedCategory.calculation_type)){
            this.form.price = price_obj && price_obj.value ? parseFloat(price_obj.value) : 0;
          } else {
            this.form.price = this.form.client_price;
          }
      },
      getClient(client) {
        this.client = client;
      },
      next() {
        this.$refs["form"].validate((valid) => {
          this.selectedCategory.second_step_attribute_count == 0 && this.activeStep == 1 ? this.activeStep++ : '';
          if (valid && this.activeStep++ > 4) {
            this.activeStep--;
          }
        });
      },
      prev() {
        this.selectedCategory.second_step_attribute_count == 0 && this.activeStep == 3 ? this.activeStep-- : '';
        if (this.activeStep-- <= 1) this.activeStep = 1;
      },
      setSecondTableValues() {
        if(this.activeStep == 2){
          this.secondStepTableData = JSON.parse(JSON.stringify(this.form.physicalDeteriorationParamsTable));
        }
        if(this.activeStep == 3){
          this.thirdStepAttributeComporisonTable = JSON.parse(JSON.stringify(this.form.attributeComporisonTable));
          this.thirdStepComporisonTable = JSON.parse(JSON.stringify(this.form.comporisonTable));

          this.thirdStepAttributeComporisonLandTable = JSON.parse(JSON.stringify(this.form.attributeComporisonLandTable || []));
          this.thirdStepComporisonLandTable = JSON.parse(JSON.stringify(this.form.comporisonLandTable || []));
        }
      },
      updateCategory(category_id) {
        this.selectedCategory = this.categories.find(el => el.id == category_id);
        this.updateList({category_id: category_id})
          .then(res => {
              this.secondStepTableData = (this.attributes && this.attributes[2] ? JSON.parse(JSON.stringify(this.attributes[2])) : []);
              this.form.physicalDeteriorationParamsTable = JSON.parse(JSON.stringify(this.secondStepTableData));

              this.thirdStepAttributeComporisonTable = (this.attributes && this.attributes[3] ? JSON.parse(JSON.stringify(this.attributes[3])) : []);
              this.form.attributeComporisonTable = JSON.parse(JSON.stringify(this.thirdStepAttributeComporisonTable));

              this.thirdStepAttributeComporisonLandTable = (this.attributes && this.attributes[4] ? JSON.parse(JSON.stringify(this.attributes[4])) : []);
              this.form.attributeComporisonLandTable = JSON.parse(JSON.stringify(this.thirdStepAttributeComporisonLandTable));
          })
          .catch(err => {
              console.log(err, 'errerr');
          });
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  // if(this.selectedCategory.calculation_type != 2){
                  //   this.form.other_inputs = [];
                  // }
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
      afterLeave() {
        this.activeStep = 1;
      },
  },
};
</script>
<style>
.deals-third-step .el-tabs__header {
  padding-left: 30px;
  padding-right: 30px;
}
.analog-titles {
  font-weight: 600;
  width: 200px;
  padding: 4px;
  padding-bottom: 13px;
  box-shadow: 2px 0px 0px 0 rgba(0, 0, 0, .1);
}
.analog-values {
  padding: 4px;
  box-shadow: 2px 0px 0px 0 rgba(0, 0, 0, .1);
}
</style>