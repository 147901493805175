<template >
    <div>
        <div class="filter-deals">
            <div @click="handleChange(category, index)" v-for="(category, index) in inventoryItems" :key="'cat_'+index">
                <el-card 
                    shadow="hover"
                    :body-style="{ width: '300px', 'min-width': '200px', cursor: 'pointer', 'background-color': (activeTab == index ? '#00b2ff' : '#d0d0d0') }" 
                >
                    <span class="font-bold text-center w-100">
                        <span class="d-block filter_categ_name">
                            {{ category.name }}
                        </span>
                        <span class="d-block deal_counts"> 
                            <i class="el-icon-collection"></i> {{ category.deals_count }} 
                        </span>
                    </span>
                    
                </el-card>
            </div>
            
        </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    
    data() {
      return {
        inventoryItems: [],
        activeTab: -1,
      };
    },
    created() {
        this.updateInventory({no_parent: true, with_count: true})
            .then(res => {
                this.inventoryItems = JSON.parse(JSON.stringify(this.inventory));
            })
            .catch(err => {
                console.log(err, 'errerr');
            });
        
    },
    computed: {
        ...mapGetters({
            inventory: "categories/inventory",
        }),
    },
    methods: {
        ...mapActions({
            updateInventory: "categories/inventory",
        }),
        handleChange(category, index) {
            this.activeTab = index;
            this.$emit('updateActiveFilter', category.id);
        }
    },
  };
</script>
<style>
    .filter-deals {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .filter_categ_name {
        font-size: 16px;
    }
    .deal_counts {
        padding-top: 10px;
        font-size: 20px;
        /* color: rgb(212, 212, 212); */
    }
</style>