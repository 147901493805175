import _ from 'lodash';
export default {
    data() {
        return {
            drawerCreate: false,
            drawerShow: false,
            drawerUpdate: false,
            reloadList: false,
            loadingData: false,
        }
    },
    methods: {
        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        listChanged() {
            this.reloadList = true;
        },
        afterFetchData() {
            this.reloadList = false;
        },
        fetchData() {
            const query = {...this.filter, ...this.pagination, ...this.sort,};
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
        refresh() {
            this.refreshData()
                .then(res => {
                    this.filterForm = JSON.parse(JSON.stringify(this.filter))
                })
                .catch(err => {})
        },
        edit(model) {         
            this.drawerUpdate = true;
        },            
    },
    destroyed() {

    },
}
