<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.deals") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = true"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <filter-table class="mb-4" ref="filter_table" @updateActiveFilter="updateActiveFilter" />

        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th v-if="columns.object_name.show">
                    {{ columns.object_name.title }}
                </th>

                <th v-if="columns.client_id.show">
                    {{ columns.client_id.title }}
                </th>

                <th v-if="columns.category_id.show">
                    {{ columns.category_id.title }}
                </th>

                <th v-if="columns.region_id.show">
                    {{ columns.region_id.title }}
                </th>

                <th v-if="columns.city_id.show">
                    {{ columns.city_id.title }}
                </th>

                <th v-if="columns.completed.show">
                    {{ columns.completed.title }}
                </th>
                
                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>

                <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                </th>

                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.object_name.show">
                  <crm-input
                      :placeholder="columns.object_name.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.object_name"
                  ></crm-input>
              </th>

              <th v-if="columns.client_id.show">
                  <crm-input
                      :placeholder="columns.client_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.client_id"
                  ></crm-input>
              </th>

              <th v-if="columns.category_id.show">
                  <crm-input
                      :placeholder="columns.category_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.category_id"
                  ></crm-input>
              </th>

              <th v-if="columns.region_id.show">
                  <crm-input
                      :placeholder="columns.region_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.region_id"
                  ></crm-input>
              </th>

              <th v-if="columns.city_id.show">
                  <crm-input
                      :placeholder="columns.city_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.city_id"
                  ></crm-input>
              </th>

              <th v-if="columns.completed.show">
                <el-select 
                    v-model="filterForm.completed" 
                    :class="mode ? 'filter__day' : 'filter__night'" 
                    :placeholder="$t('message.status')"
                    clearable
                    size="mini"
                >
                        <el-option
                            v-for="item in statusOptions"
                            :key="'item_s'+item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                </el-select>

              </th>

              <th v-if="columns.created_at.show">
                  <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                  <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                  ></crm-date-picker>
              </th>

              <th
                  class="settinW"
                  v-if="columns.settings.show"
              ></th>
          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="deal in list" :key="deal.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ deal.id }}
                </td>

                <td v-if="columns.object_name.show">
                    {{ deal.object_name }}
                </td>

                <td v-if="columns.client_id.show">
                    {{ deal.client ? deal.client.name : '' }}
                </td>

                <td v-if="columns.category_id.show">
                    {{ deal.category ? deal.category.name : '' }}
                </td>

                <td v-if="columns.region_id.show">
                    {{ deal.region ? deal.region.name : '' }}
                </td>

                <td v-if="columns.city_id.show">
                    {{ deal.city ? deal.city.name : '' }}
                </td>

                <td v-if="columns.completed.show">
                    {{ deal.completed ? $t('message.completed_z') : $t('message.in_process') }}
                </td>
               
                <td v-if="columns.created_at.show">
                    {{ deal.created_at }}
                </td>

                <td v-if="columns.updated_at.show">
                    {{ deal.updated_at }}
                </td>

                <td v-if="columns.settings.show" class="settings-td d-flex justify-between">
                    <el-dropdown v-if="deal.completed" size="mini" @command="handleFileCommand">
                        <el-button class="mr-2" size="mini" type="primary" icon="el-icon-document-copy"></el-button>
                        <el-dropdown-menu slot="dropdown" size="mini">      
                            <el-dropdown-item :command="{action: 'downloadExcel', model: deal}" icon="fa-solid fa-x el-icon--left">
                                {{ $t('message.downloadExcel') }}
                            </el-dropdown-item>
                            
                            <el-dropdown-item v-for="(d_template, index) in deal.documentTemplates" :key="'d_temp_'+index" :command="{action: 'downloadWord', model: deal, template: d_template}" icon="fa-solid fa-w el-icon--left">
                                {{ d_template.name }}
                            </el-dropdown-item>

                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button @click="edit(deal)" size="mini" type="primary" icon="el-icon-edit"></el-button>
                    <el-button @click="destroyDeal(deal)" size="mini" type="danger" icon="el-icon-delete"></el-button>
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false"  
               :visible.sync="drawerCreate" 
               ref="drawerCreate"
               size="90%"
               @opened="drawerOpened('drawerCreateChild')"
               @closed="drawerClosed('drawerCreateChild')"
               >
                <div>
                    <crm-create ref="drawerCreateChild" drawer="drawerCreate"> </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="90%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer> 
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import filterTable from "./components/filterTable";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "deals",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
        filterTable
    },

    data() {
        return {
            statusOptions: [
                {
                    value: false,
                    label: this.$t('message.in_process')
                }, 
                {
                    value: true,
                    label: this.$t('message.completed_z')
                },
            ]
        };
    },

    computed: {
        ...mapGetters({
            list: "deals/list",
            columns: "deals/columns",
            pagination: "deals/pagination",            
            filter: "deals/filter",
            sort: "deals/sort",
            token: "auth/token",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "downloadExcel", "downloadWord", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "deals/index",
            downloadExcel: "deals/downloadExcel",
            downloadWord: "deals/downloadWord",
            setPagination: "deals/setPagination",
            updateSort: "deals/updateSort",
            updateFilter: "deals/updateFilter",
            updateColumn: "deals/updateColumn",
            updatePagination: "deals/updatePagination",
            show: "deals/show",
            empty: "deals/empty",
            delete: "deals/destroy",
            refreshData: "deals/refreshData",
        }),
        handleFileCommand({action, model, template}) {
            if(action == 'downloadExcel'){
                this.downloadExcelFiles(model);
            }
            if(action == 'downloadWord') {
                this.downloadWordFiles(model, template);
            }
        },
        updateActiveFilter(category_id){
            this.$set(this.filterForm, 'category_id', category_id)
        },
        downloadExcelFiles(model) {
            this.downloadExcel({id: model.id})
                .then(res => {
                    let blob = new Blob([res.data], {
                        type: "application/vnd.ms-excel"
                    })
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob)
                    link.setAttribute("download", (model.id+'-deal.xlsx'));
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    console.log(err, 'errrr');
                });
        },
        downloadWordFiles(model, template) {
            this.showFileOpen(model, template);
        },
        showFileOpen(model, template) {
            let file_name = template.name + '.docx';
            this.$openOffice(file_name, "create", JSON.stringify(model));
        },

        destroyDeal(deal) {
            this.$confirm(
              this.$t('message.do_you_really_want_to_do_this'),
              this.$t("message.warning"), {
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
                type: "warning"
              }
            )
            .then(() => {
                this.destroy(deal);
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t("message.operation_canceled")
              });
            });
        }
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('deals/EMPTY_LIST');
        next()
    },
};
</script>

