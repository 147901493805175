<template >
    <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <template>
                <h4>{{ table.table_name }}</h4>
                <el-table
                    :data="table.table_values"
                    show-summary
                    :summary-method="getMainTableSummaries"
                    border
                    style="width: 100%"
                >
                    <el-table-column :label="$t('message.criteria')" width="180">
                        <template slot-scope="scope">
                            {{ scope.row.name }}
                        </template>
                    </el-table-column>

                    <el-table-column v-for="(col, index) in table.column_names" :key="'name_'+index" :label="col.name" width="180" >
                        <template slot-scope="scope">
                            <div :class="col.key == scope.row.key ? 'mm_constant_values' : ''">
                                <el-input disabled v-model="scope.row.values[index]" type="number" size="mini"></el-input>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Вес критерия">
                        <template slot-scope="scope">
                            {{ scope.row.total_last }}
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props:{
        selectedTable:{
            type: Object,
            default: Object
        },
        tables:{
            type: Array,
            default: Array
        },
        drawerOpened:{
            type: Boolean
        }
    },
    data() {
        return {
            table: {}
        };
    },
    watch: {
        selectedTable: {
            handler: async function(newVal, oldVal) {
                this.table = JSON.parse(JSON.stringify(newVal));
            },
            deep: true,
            immediate: true
        },
        tables: {
            handler: async function(newVal, oldVal) {
                this.table.table_values.forEach(element => {
                    element.values = [];
                });
                newVal.forEach((table, table_index) => {
                    if(table.name == 'main'){
                        let values = table.table_values ? table.table_values.map(el => el.total_last) : [];
                        this.table.table_values[0].values = JSON.parse(JSON.stringify(values));
                    } else if(table.type == 'method') {
                        this.table.table_values.forEach((self_values, self_index) => {
                            if(self_index != 0){
                                let relative_row = table.table_values.find(el => el.name == self_values.name);
                                self_values.values.push(relative_row.total_last);
                            }
                        });
                    }
                });
            },
            deep: true,
            immediate: true
        },
        "table.table_values": {
            handler: async function(newVal, oldVal) {
                this.updateTableValues();
            },
            deep: true,
            immediate: true
        },
    },
    created() {
        
    },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        ...mapActions({
        }),

        updateTableValues() {
            let total_last = 0;
            this.table.table_values.forEach((element, index) => {
                if(index != 0){
                    element.total_last = this.rowCalculation(element)
                    total_last += element.total_last;
                }
            });
            this.$set(this.table, 'total_last', parseFloat(total_last || 0));
            this.$emit('input', this.table);
        },

        rowCalculation(row) {
            let result = 0;
            if(row.values && row.values.length > 0 && this.table.table_values && this.table.table_values.length > 0){
                let first_row_values = this.table.table_values[0].values;
                row.values.forEach((element, index) => {
                    result +=(parseFloat(element || 0) * parseFloat(first_row_values[index] || 0));
                });
            }
            return _.round(result, 2);
        },

        getMainTableSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (index === 5) {
                    sums[index] = this.table.total_last;
                    return;
                }
            });

            return sums;
        },
    },
};
</script>
<style>
  .mm_constant_values input {
    background-color: rgb(255, 186, 186);
    width: 100%;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>