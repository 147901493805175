<template >
  <div>
    <div v-for="(table, index) in fourthStepLandDefaults" :key="'m_table_'+index">
      <tableCriteria 
        v-if="table.type == 'criteria'"
        :selectedTable="table"
        @input="getValues($event, index)"
        :drawerOpened="drawerOpened" 
      >
      </tableCriteria>

      <tableMethod 
        v-if="table.type == 'method'"
        :selectedTable="table" 
        @input="getValues($event, index)"
        :drawerOpened="drawerOpened" 
      >
      </tableMethod>

      <tableTotal 
        v-if="table.type == 'total'"
        :selectedTable="table"
        :tables="otherTables"
        @input="getValues($event, index)"
        :drawerOpened="drawerOpened" 
      >
      </tableTotal>

      <tableWeightSignificance 
        v-if="table.type == 'weight_significance'"
        :selectedTable="table" 
        @input="getValues($event, index)"
        :drawerOpened="drawerOpened" 
      >
      </tableWeightSignificance>
      
    </div>
  </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import tableCriteria from "./table-criteria";
import tableMethod from "./table-method";
import tableTotal from "./table-total";
import tableWeightSignificance from "./table-weight-significance";

export default {
    components: {
      tableCriteria,
      tableMethod,
      tableWeightSignificance,
      tableTotal
    },
    props:{
      drawerOpened:{
        type: Boolean
      }
    }, 
    data() {
      return {
        tables: [],
      };
    },
    watch: {
      drawerOpened: {
          handler: async function(newVal, oldVal) {
              if(newVal){
                this.tables = JSON.parse(JSON.stringify(this.fourthStepLandDefaults));
              }
          },
          deep: true,
          immediate: true
      },
    },
    created() {
      
    },
    computed: {
        ...mapGetters({
          fourthStepLandDefaults: "deals/fourthStepLandDefaults",
        }),
        otherTables() {
          return this.tables.filter(el => el.type != 'total');
        }
    },
    methods: {
        ...mapActions({
        }),
        getValues(val, index) {
          this.tables[index] = val;
          if(val.type != 'total'){
            this.tables = JSON.parse(JSON.stringify(this.tables));
          }
          this.$emit('input', this.tables);
        }
    },
  };
</script>
<style>
.mm_constant_values input {
  background-color: rgb(255, 186, 186);
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>