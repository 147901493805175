<template>
    <div>
      <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.currency')" filterable
        :size="size" class="w-100">
        <el-option v-for="(currency, index) in currencies" :key="'currencies-' + index" :label="currency.name" :value="currency.id"></el-option>
      </el-select>
    </div>
  </template>
  <script>
    import {mapGetters, mapActions} from "vuex";
    export default {
      props: {
        size: {
          default: 'small'
        },
        placeholder: {
          default: null,
        },
        id:{
          default: null
        }
      },
      watch:{
        id:{
          handler: function() {
              this.selected = this.id;
              this.setActiveWhenEmpty();
              
          },
          immediate: true
        }
      },
      data() {
        return {
          selected: null,
        }
      },
      mounted() {
        if (this.currencies && this.currencies.length === 0)  
            this.updateInventory()
                .then(res => {
                    this.setActiveWhenEmpty();
                });
      },
      computed: {
        ...mapGetters({
            currencies: 'currency/inventory'
        })
      },
      methods: {
        ...mapActions({
          updateInventory: 'currency/inventory'
        }),
        setActiveWhenEmpty() {
            if(!this.selected){
                let active_currency = this.currencies.find(el => el.active == true);
                if(active_currency){
                    this.selected = active_currency.id;
                    this.dispatch(this.selected);
                }
            }
        },
        dispatch(e) {
            let selectedCurrency = this.currencies.find(el => el.id == e);
            this.$emit('setCurrencyRate', selectedCurrency);
            this.$emit('input', e);
            this.selected = e;
        }
      },
    }
  
  </script>
  