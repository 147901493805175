<template >
  <div>
    <div v-for="(table, index) in all_tables" :key="'m_table_'+index">
      <tableCriteria 
        v-if="table.type == 'criteria'"
        :selectedTable="table"
        @input="getValues($event, index)"
        :drawerOpened="drawerOpened" 
      >
      </tableCriteria>

      <tableMethod 
        v-if="table.type == 'method'"
        :selectedTable="table" 
        @input="getValues($event, index)"
        :drawerOpened="drawerOpened" 
      >
      </tableMethod>

      <tableTotal 
        v-if="table.type == 'total'"
        :selectedTable="table"
        :tables="otherTables"
        @input="getValues($event, index)"
        :drawerOpened="drawerOpened" 
      >
      </tableTotal>
    </div>
  </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import tableCriteria from "./table-criteria";
import tableMethod from "./table-method";
import tableTotal from "./table-total";

export default {
    components: {
      tableCriteria,
      tableMethod,
      tableTotal
    },
    props:{
      drawerOpened:{
        type: Boolean
      },
      fourthStepTables:{
        type: Array,
        default: Array,
      }
    }, 
    data() {
      return {
        tables: [],
        all_tables: []
      };
    },
    watch: {
      fourthStepTables: {
          handler: async function(newVal, oldVal) {
              if(newVal && Array.isArray(newVal) && newVal.length > 0){
                this.all_tables = JSON.parse(JSON.stringify(newVal));
              }else {
                this.all_tables = JSON.parse(JSON.stringify(this.fourthStepAvtoDefaults));
              }
          },
          deep: true,
          immediate: true
      },
    },
    created() {
      
    },
    computed: {
        ...mapGetters({
          fourthStepAvtoDefaults: "deals/fourthStepAvtoDefaults",
        }),
        otherTables() {
          return this.tables.filter(el => el.type != 'total');
        }
    },
    methods: {
        ...mapActions({
        }),
        getValues(val, index) {
          this.tables[index] = val;
          if(val.type != 'total'){
            this.tables = JSON.parse(JSON.stringify(this.tables));
          }
          this.$emit('input', this.tables);
        }
    },
  };
</script>
<style>
.mm_constant_values input {
  background-color: rgb(255, 186, 186);
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>