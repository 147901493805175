<template >
    <div>
        <el-form ref="form" :model="form" label-position="top" :rules="rules">
          <div class="app-modal__body p-5 pb-0">
            <div class="timeline-items__right rounded-sm w-100 p-4">
              <p class="font-bold">Добавить объекты для сравнения {{ additional_title }}</p>
              <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item prop="object_name" :label="$t('message.object_name')" >
                        <el-input
                            :placeholder="$t('message.object_name')"
                            v-model="form.object_name"
                        ></el-input>
                      </el-form-item>  
                  </el-col>

                  <el-col :span="8">
                    <el-form-item prop="city_id" :label="$t('message.city')" >
                        <select-city
                          :size="'large'"
                          :placeholder="$t('message.city')"
                          :id="form.city_id"
                          v-model="form.city_id"
                        >
                        </select-city>
                      </el-form-item>  
                  </el-col>

                  <el-col :span="8">
                    <el-form-item prop="region_id" :label="$t('message.region')" >
                      <select-region
                        :size="'large'"
                        :placeholder="$t('message.region')"
                        :id="form.region_id"
                        v-model="form.region_id"
                      >
                      </select-region>
                      </el-form-item>  
                  </el-col>
                </el-row>

                <el-row :gutter="20">

                  <el-col :span="4" v-if="selectedCategory && selectedCategory.calculation_type && [2, 3].includes(selectedCategory.calculation_type)">
                    <el-form-item prop="quantity" :label="$t('message.quantity')" >
                        <el-input
                            :placeholder="$t('message.quantity')"
                            v-model="form.quantity"
                            type="number"
                        ></el-input>
                      </el-form-item>  
                  </el-col>

                  <el-col :span="6">
                    <el-form-item prop="price" :label="$t('message.price')" >
                        <el-input
                            :placeholder="$t('message.price')"
                            v-model="form.price"
                            type="number"
                        ></el-input>
                    </el-form-item>  
                  </el-col>

                  <el-col :span="6">
                    <el-form-item prop="currency_rate" :label="$t('message.rate')" >
                        <el-input
                            :disabled="form.currency && form.currency.active"
                            v-model="form.currency_rate"
                            :old="form.currency_rate"
                            type="number"
                          >
                          <template slot="append">{{ calculateInActiveCurrency | formatNumber(1) }} {{ $currency }}</template>
                        </el-input>
                      </el-form-item>  
                  </el-col>
                  
                  <el-col :span="8">
                    <el-form-item prop="currency_id" :label="$t('message.currency')" >
                      <div class="d-flex">
                        <select-currency
                          :size="'large'"
                          class="w-100"
                          :placeholder="$t('message.currency')"
                          @setCurrencyRate="setCurrencyRate"
                          :id="form.currency_id"
                          v-model="form.currency_id"
                        >
                        </select-currency>
                        <el-button @click="addExamples()" class="ml-2" type="primary" icon="el-icon-plus">{{ $t('message.add') }}</el-button>
                      </div>
                    </el-form-item>  
                  </el-col>

              </el-row>
              <template>
                <el-table
                  :data="comporisonTable"
                  border
                  style="width: 100%">
                  <el-table-column
                    type="index"
                    width="50">
                  </el-table-column>
                  <el-table-column
                    prop="object_name"
                    :label="$t('message.object_name')">
                  </el-table-column>
                  <el-table-column
                    prop="city"
                    :label="$t('message.city')">
                    <template slot-scope="scope">
                      {{ scope.row.city ? scope.row.city.name : '' }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="region"
                    :label="$t('message.region')">
                    <template slot-scope="scope">
                      {{ scope.row.region ? scope.row.region.name : '' }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="selectedCategory && selectedCategory.calculation_type && [2, 3].includes(selectedCategory.calculation_type)"
                    prop="quantity"
                    :label="$t('message.quantity')">
                  </el-table-column>
                  <el-table-column
                    v-if="selectedCategory && selectedCategory.calculation_type && [2, 3].includes(selectedCategory.calculation_type)"
                    prop="price_for_quantity"
                    label="Цена за количество">
                    <template slot-scope="scope">
                      {{ thirdTableRowTotal(scope.row) | formatMoney }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    label="Цена объекта">
                    <template slot-scope="scope">
                      {{ scope.row.price }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="currency_rate"
                    :label="$t('message.rate')">
                    <template slot-scope="scope">
                      {{ scope.row.currency_rate }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="currency_id"
                    :label="$t('message.currency')">
                    <template slot-scope="scope">
                      {{ scope.row.currency ? scope.row.currency.name : '' }}
                    </template>
                  </el-table-column>
                  <el-table-column width="90">
                    <template slot-scope="scope">
                      <el-button @click="thirdTableRowRemove(scope.row, scope.$index)" type="danger" icon="el-icon-delete"></el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
          <div class="app-modal__body p-5 pb-0">
            <div class="timeline-items__right rounded-sm w-100 p-4">
              <p class="font-bold mt-5">Атрибуты для сравнения {{ additional_title }}</p>
              <div v-for="(analog_attribute, index) in attributeComporisonTable" :index="'analog_attr_'+index" class="d-flex text-center">
                <div>
                  <h4 v-if="index == 0" style="height: 40px;">{{ $t('message.attribute') }}</h4>
                  <div class="analog-titles">
                    <div class="pt-2">
                      {{ analog_attribute.attribute ? analog_attribute.attribute.name : '' }}
                    </div>
                  </div>
                </div>
                <div class="pl-4">
                  <h4 v-if="index == 0" style="height: 40px;">Объект оценки</h4>
                  <div class="analog-values pr-4">
                    <div class="d-flex">
                      <el-input
                        size="small"
                        v-model="analog_attribute.value_1"
                      ></el-input>
                    </div>
                  </div>
                </div>
                <div style="width: 380px;" v-for="(analog, val_index) in analog_attribute.analogs" :index="'analog_val_'+val_index" class="pl-4">
                  <h4 v-if="index == 0" style="height: 40px;">{{ comporisonTable[val_index].object_name }} ( Аналог {{ val_index + 1 }} )</h4>
                  <div class="analog-values pr-4">
                    <div class="d-flex">
                      <el-input size="small" v-model="analog.value_1">
                      </el-input>
                      <div style="width: 150px;">
                        <el-input
                          size="small"
                          class="ml-2"
                          type="number"
                          v-model="analog.value_2"
                        >
                          <template slot="append">%</template>
                        </el-input>
                      </div>
                    </div>
                    <div class="mt-1 w-100 d-flex justify-between" style="font-size: 14px;">
                        <span>Скорр-ная цена:</span>
                        <span class="font-bold">{{ (analog.total || 0) | formatNumber(1) }} {{ $currency }}</span>
                    </div>
                  </div>
                </div>

              </div>

              <div class="mt-5 text-right">
                <span class="font-bold">Итоговая стоимость сум:</span> {{ avarageTotal | formatNumber(1) }} {{ $currency }}
              </div>
            </div>
          </div>
        </el-form>
    </div>
  </template>
  
<script>
  import { mapGetters, mapActions } from "vuex";
  import SelectCity from "@/components/inventory/select-city.vue";
  import SelectRegion from "@/components/inventory/select-region.vue";
  import SelectCurrency from "@/components/inventory/select-currency.vue";

  export default {
    components: {
      SelectCity,
      SelectRegion,
      SelectCurrency,
    },
    props: {
        thirdStepComporisonTable: {
            type:Array,
            default:Array
        },
        attributeComporison: {
            type:Array,
            default:Array
        },
        selectedCategory: {
            type:Object,
            default:Object
        },
        additional_title: {
            type:String,
            default:''
        },
    },
    data() {
      return {
        formModel: {
            city: '',
            region: '',
            object_name: '',
            field: '',
            price: '',
            quantity: 1,
            currency_rate: 1,
            currency_id: null,
            currency: '',
        },
        form: {},
        comporisonTable: [],
        attributeComporisonTable: [],
        totalTable: [
          {
            name: 'Скорректированная цена',
            values: []
          },
          {
            name: 'Весовые коэффициенты',
            values: []
          },
          {
            name: 'Цена с учетом удельного веса',
            values: []
          }
          
        ],
      };
    },
    watch: {
        // props watch
        attributeComporison: {
            handler: async function(newVal, oldVal) {     
                this.attributeComporisonTable = JSON.parse(JSON.stringify(newVal));
            },
            deep: true,
            immediate: true
        },

        thirdStepComporisonTable: {
            handler: async function(newVal, oldVal) {              
                this.comporisonTable = JSON.parse(JSON.stringify(newVal));
            },
            deep: true,
            immediate: true
        },

        // data watch
        attributeComporisonTable: {
            handler: async function(newVal, oldVal) {
              if(this.comporisonTable.length > 0){
                this.updateData();
              }
              this.$emit('attributeComporisonTable', newVal);
            },
            deep: true,
        },

        comporisonTable: {
            handler: async function(newVal, oldVal) {
              this.$emit('comporisonTable', newVal);
              if(newVal && newVal.length > 0 && ((oldVal && oldVal.length == 0) || !oldVal)){
                this.updateData();
              }
            },
            deep: true,
        },
    },
    created() {},
    computed: {
        ...mapGetters({
          rules: "deals/thirdStepRules",
          cities: "cities/inventory",
          currencies: "currencies/inventory",
          regions: 'regions/inventory'
        }),
        calculateInActiveCurrency() {
          let total = parseFloat(this.form.currency_rate || 0) * parseFloat(this.form.price || 0);
          return _.round(total, 2);
        },
        avarageTotal() {
          let attr_count = this.attributeComporisonTable ? this.attributeComporisonTable.length : 0;
          let comp_count = this.comporisonTable ? this.comporisonTable.length : 0;
          let total = 0;
          if(attr_count > 0 && comp_count > 0){
            let analogs = this.attributeComporisonTable[attr_count-1].analogs;
            if(analogs && Array.isArray(analogs)){
              let sum = analogs.reduce((accumulator, row) => {
                  return accumulator + (this.stringToFloat(row.total) );
              }, 0);
              total = sum / comp_count;
            }
          }
          return _.round(parseFloat(total), 2);
        }
    },
    methods: {
        ...mapActions({
        }),
        updateData(){
          if(this.attributeComporisonTable && this.attributeComporisonTable.length > 0 && this.comporisonTable && this.comporisonTable.length > 0){
            this.attributeComporisonTable.forEach((element, el_index) => {
              if(el_index == 0 && element.analogs){
                element.analogs.forEach((col, col_index) => {
                  let row = this.comporisonTable[col_index];
                  let total = row.price * row.currency_rate;
                  total = total * (100 + parseFloat(col.value_2 || 0))/100;
                  this.$set(col, 'total', total);
                });
              } else if (el_index > 0 && element.analogs) {
                element.analogs.forEach((col, col_index) => {
                  let total = this.attributeComporisonTable[el_index-1].analogs[col_index].total;
                  total = total * (100 + parseFloat(col.value_2 || 0))/100;
                  this.$set(col, 'total', total);
                });
              }
            });
          }

        },
        setCurrencyRate(currency) {
          if(currency){
            this.form.currency = currency;
            this.$set(this.form, 'currency_rate', currency.rate);
          }
        },
        addExamples() {
          this.$refs["form"].validate((valid) => {
            if (valid) {
              this.form.city = this.cities.find(el => el.id == this.form.city_id);
              this.form.region = this.regions.find(el => el.id == this.form.region_id);
              this.form.currency = this.form.currency ? this.form.currency : this.currencies.find(el => el.active == true);
              
              this.comporisonTable.push(JSON.parse(JSON.stringify(this.form)));
              for (const element of this.attributeComporisonTable) {
                  let el_analogs = element.analogs && Array.isArray(element.analogs) ? JSON.parse(JSON.stringify(element.analogs)) : []
                  this.$set(element, 'analogs', el_analogs);
                  element.analogs.push(
                    JSON.parse(JSON.stringify({
                      value_1: element.value_1,
                      value_2: element.value_2,
                    }))
                  );

                  this.$set(element, 'analogs', JSON.parse(JSON.stringify(element.analogs)));
              }
              this.emptyThirdModel();
            }
          });
        },
        thirdTableRowRemove(row, index) {
            this.comporisonTable.splice(index, 1);
            for (const element of this.attributeComporisonTable) {
                if(element.analogs && element.analogs.length > 0 && element.analogs[index]){
                    element.analogs.splice(index, 1);
                    this.$set(element, 'analogs', JSON.parse(JSON.stringify(element.analogs)));
                }
            }
        },
        emptyThirdModel() {
            this.form = JSON.parse(JSON.stringify(this.formModel));
        },
        thirdTableRowTotal(row) {
            return this.stringToFloat(row.field) * this.stringToFloat(row.price);
        },
        stringToFloat(str) {
            const num = parseFloat(str || 0);
            return isNaN(num) ? 0 : num;
        },
    },
  };
  </script>
  