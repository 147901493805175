<template>
  <div class="d-flex">
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.client')" filterable clearable
      :size="size" class="w-100">
      <el-option v-for="(client, index) in clients" :key="'clients-' + index" :label="client.name" :value="client.id"></el-option>
    </el-select>
    <el-button @click="drawerCreate = true" class="ml-2" :size="size" icon="el-icon-plus"></el-button>
    <el-drawer
      :with-header="false"  
      :visible.sync="drawerCreate" 
      ref="drawerCreate"
      size="70%"
      :append-to-body="true"
      @opened="drawerOpened('drawerCreateChild')"
      @closed="drawerClosed('drawerCreateChild')"
    >
      <div>
          <crm-create 
            ref="drawerCreateChild"
            drawer="drawerCreate"> 
          </crm-create>
      </div>
    </el-drawer>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  import child_list from "@/utils/mixins/child_list";
  import CrmCreate from "@/views/clients/components/crm-create";

  export default {
    mixins: [child_list],
    components: {
        CrmCreate,
    },
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.clients && this.clients.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        clients: 'clients/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'clients/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
        let client = this.clients.find(el => el.id == e);
        client = client ? JSON.parse(JSON.stringify(client)) : '';
        this.$emit('getClient', client);
      },
      fetchData() {
        this.updateInventory();
      }
    },
  }

</script>
