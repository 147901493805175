<template >
    <div>
      <div class="app-modal__box">
        <div class="app-modal__in">
          <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0"> {{ $t("message.payment") }}</p>
            <div>
               <crm-store-update-close
                  :permission="$options.name"
                  :button_type="'store'"
                  :loading="loadingButton"
                  @c-submit="submit(true)"
                  @c-close="close()"
              ></crm-store-update-close>
            </div>
          </div>
        </div>
        <!-- app-modal__header end -->
  
        <div class="app-modal__body p-5 pb-0">
          <div class="timeline-items__right rounded-sm w-100 p-4">
                <p class="font-bold mb-4">
                    {{ $t('message.paymentType') }}
                </p>
                <select-payment-type
                    :placeholder="$t('message.paymentType')"
                    v-model="form.payment_type_id"
                    :size="'large'"
                    :id="form.payment_type_id"
                    @input="addNewPayment"
                ></select-payment-type>
          </div>    
        </div>
        <div class="app-modal__body p-5 pb-0">
          <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-row v-for="(deal_payment, index) in payments" :key="'pay_'+index" :gutter="20">
                <el-col :span="6">
                    <p class="font-bold">
                        {{ deal_payment.paymentType }}

                        <span v-if="deal_payment.paid" class="mm_paid">
                            {{ $t('message.paid') }}
                        </span>
                        <span v-else class="mm_not_paid">
                            {{ $t('message.not_paid') }}
                        </span>
                    </p>

                </el-col>
                <el-col :span="4">
                    <el-input
                        v-model="deal_payment.price"
                        type="number"
                    >
                    </el-input>
                </el-col>
                <el-col :span="6">
                    <el-input
                        v-model="deal_payment.currency_rate"
                        type="number"
                    >
                        <template slot="append">{{ (deal_payment.price * deal_payment.currency_rate) | formatNumber(1) }} {{ $currency }} </template>
                    </el-input>
                </el-col>
                <el-col :span="6">
                    <select-currency
                        :size="'large'"
                        :placeholder="$t('message.currency')"
                        @setCurrencyRate="setCurrencyRate($event, index)"
                        :id="deal_payment.currency_id"
                        v-model="deal_payment.currency_id"
                    >
                    </select-currency>
                </el-col>
                <el-col :span="2" class="text-right">
                    <el-button @click="removePayment(deal_payment, index)" type="danger" icon="el-icon-delete"></el-button>
                </el-col>
            </el-row>
            <div class="text-right">
                <p class="font-bold">
                    {{ $t('message.total') }} {{ paymentTotal | formatNumber(1) }} {{ $currency }}
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import SelectPaymentType from "@/components/inventory/select-payment-type";
import SelectCurrency from "@/components/inventory/select-currency.vue";

export default {
    mixins: [form, drawer],
    components: {
        SelectPaymentType,
        SelectCurrency
    },
    props:{
        old_payments: {
            type: Array,
            default: Array,
        },
    },
    data() {
      return {
        payments: []
      };
    },
    computed: {
        ...mapGetters({
            paymentType: 'paymentTypes/model'
        }),
        paymentTotal() {
            let total = this.payments.reduce((accumulator, currentValue) => accumulator + currentValue.price * currentValue.currency_rate, 0,);
            this.$emit('input', total);
            return total;
        }
    },
    methods: {
        ...mapActions({
            save: "dealPayment/update",
            showPaymentType: 'paymentTypes/show'
        }),
        afterOpen() {
            this.payments = JSON.parse(JSON.stringify(this.old_payments));
        },
        setCurrencyRate(currency, index) {
            if(currency){
                this.selectedCurrency = currency;
                this.$set(this.payments[index], 'currency_rate', currency.rate);
            }
        },
        addNewPayment(payment_type_id) {
            if(!this.paymentExists(payment_type_id)){
                this.showPaymentType(payment_type_id)
                    .then(res => {
                        this.addPayment();
                    })
                    .catch(err => {
                    });
            } else {
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.already_exists')
                });
            }
        },
        paymentExists(payment_type_id) {
            let payment = this.payments.find((el) => el.payment_type_id == payment_type_id);
            return payment;
        },
        addPayment() {
            let new_payment = {
                id: '',
                payment_type_id: this.paymentType.id,
                paymentType: this.paymentType.name,
                price: 0,
                paid: false,
                currency_rate: 1,
                currency_id: null,
                payment_date: '',
                comment: '',
            }
            this.payments.push(new_payment)
        },
        removePayment(payment, index) {
            this.payments.splice(index, 1);
        },
        submit(close = true) {
            this.$emit('setPayments', this.payments);
            this.close();
        },
    },
};
</script>
<style>
.mm_paid {
    color: white;
    padding: 4px;
    border-radius: 4px;
    background-color: rgb(44, 211, 72);
}

.mm_not_paid {
    color: white;
    padding: 4px;
    border-radius: 4px;
    background-color: rgb(247, 82, 82);
}

</style> 